<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Новый элемент"
        create
        action-one="hint/one"
        url-update="/scoring-criteria/update/:id"
        url-back="/scoring-criteria"
    >
        <hint-form type="subtask-scoring-criteria" :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import HintForm from '@/components/forms/HintForm.vue'
export default {
    name: 'ScoringCriteriaCreate',
    components: { FormView, HintForm }
}
</script>